import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Tabbar,
  Section,
  Cell,
  ButtonCell,
  Modal,
} from "@telegram-apps/telegram-ui";
import {
  QR_SCANNER,
  ADMIN_PAGE,
  USER_PAGE,
  USRES_SETTINGS,
  HOME_PATH,
} from "../constants/routes";
import { MdOutlineQrCode2, MdHome } from "react-icons/md";
const Menu = observer(() => {
  const [path, setPath] = useState(HOME_PATH);
  const location = useLocation();
  const navigate = useNavigate();
  const goToContact = (path) => {
    navigate(path);
  };
  useEffect(() => {
    console.log(
      "URL изменился:",
      location.pathname + location.search + location.hash
    );
    setPath(location.pathname);
  }, [location]);
  return (
    <>
      <div style={{ position: "absolute", zIndex: "2" }}>
        <Tabbar>
          <Tabbar.Item
            text="Главная"
            onClick={() => goToContact(HOME_PATH)}
            selected={path === HOME_PATH}
          >
            <MdHome size={16} />
            {/* ADMIN_PAGE */}
          </Tabbar.Item>
          {/* <Tabbar.Item
            text="Пользователь"
            onClick={() => goToContact(USRES_SETTINGS)}
            selected={path === USRES_SETTINGS}
          >
            <MdOutlineQrCode2 size={16} />
          </Tabbar.Item> */}
          <Tabbar.Item
            text="QR сканнер"
            onClick={() => goToContact(QR_SCANNER)}
            selected={path === QR_SCANNER}
          >
            <MdOutlineQrCode2 size={16} />
          </Tabbar.Item>
        </Tabbar>
      </div>
    </>
  );
});

export default Menu;
