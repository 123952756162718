import axios from 'axios'
import { toast } from 'react-hot-toast';
// import { getTokenFromCookie } from '../cookie/cookie'

export const URL = "http://localhost:3001/six_app"


// Получить пользоваетеля
export async function Login(user_tg_id) {
    try {
        const response = await axios.post(`${URL}/user/login?tg_user_id=${user_tg_id}`);
        return response.data

    } catch (error) {
        console.error(error);
        toast(`Неаудалось получить данные: ${error.message}`, { type: "error" });
        return null
    }

}