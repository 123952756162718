import { Modal, Button } from "@telegram-apps/telegram-ui";
import { all } from "axios";
import React, { useState } from "react";
import { QrScanner } from "react-qrcode-scanner";
import styled from "styled-components";
import Html5QrcodePlugin from "./Html5QrcodePlugin";
const CamerakWrapper = styled.div`
  height: 70vh;
`;

const QrReaderScan = () => {
  const [result, setResult] = useState(null);

  const handleScan = (value) => {
    alert(value);
    console.log({ value });
    setResult(value);
  };

  const handleError = (error) => {
    alert(error);

    console.log({ error });
  };
  const onNewScanResult = (decodedText, decodedResult) => {
    // handle decoded results here
    alert(decodedText);
    console.log({ decodedText });
    setResult(decodedText);
  };
  return (
    <>
      <p>{result}</p>
      <Modal trigger={<Button size="m">Сканировать</Button>}>
        <CamerakWrapper>
          <Html5QrcodePlugin
            fps={10}
            qrbox={250}
            disableFlip={false}
            facingMode="environment" // Задняя камера
            verbose={true}
            qrCodeSuccessCallback={onNewScanResult}
          />
        </CamerakWrapper>
      </Modal>
    </>
  );
};

export default QrReaderScan;
