import styled from "styled-components";

const StyledQrModalContetntWrapper = styled.div`
  display: flex;
  height: 70vh;
  padding-top: 2rem;
  flex-direction: column;
  row-gap: 1rem;
  text-align: center;
`;

const QrModalContetntWrapper = ({ children }) => {
  return (
    <StyledQrModalContetntWrapper>{children}</StyledQrModalContetntWrapper>
  );
};

export default QrModalContetntWrapper;
