// import logo from './logo.svg';
import '@telegram-apps/telegram-ui/dist/styles.css';
import './App.css';
import React, { useEffect, useState } from "react";
import { AppRoot } from '@telegram-apps/telegram-ui';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Admin from './pages/Admin'
import User from './pages/User'
import { Login } from './api/api'
import { userStore } from './stores/UserStore'
import Menu from './components/menu'
import QrReaderScan from './components/Html5QrcodePlugin'
import { QR_SCANNER } from './constants/routes'
// return '6935066908'
function App() {
  const [socket, setSocket] = useState(null);
  const [role, setRole] = useState(null);
  const tgMetodGetId = () => { return '6935066908' }


  async function initUser(tg_user_id) {
    const userData = await Login(tg_user_id);
    if (userData) {
      console.log("Записали юзера в store:", userData.user);
      console.log(userData)
      // saveTokenToCookie(userData.token)
      userStore.setUser(userData); // записываем пользователя в store
      setRole(userData.role)
      // setRole(userData.user.role) 
    }
  }


  useEffect(() => {
    initUser(String(tgMetodGetId()))
  })


  return (
    <>
      <AppRoot
        appearance="dark"
      >

        <Router>
          <Menu />
          <Routes>
            <Route path="/" element={<User />} />
            <Route path={QR_SCANNER} element={<QrReaderScan />} />


          </Routes>
        </Router>
      </AppRoot>
    </>
  );
}

export default App;
